
































































































































































































































































































































































































































































































































































































  import Vue from 'vue'
  import { Component, Watch } from 'vue-property-decorator'
  import { appendSitePrefix } from '@/utils/routeUtils'
  import { vxm } from '@/store'
  import PdfViewer from '@/components/PdfViewer.vue'

  @Component({
    components: { PdfViewer },
  })
  export default class SupplierInvoice extends Vue {
    loading = false
    invoice: any = {}
    cleanInvoice: any = {}
    pdfData = ''
    showPdf = false
    suppliers: any = []
    togglePdf = true
    saveErrorMessage = ''
    showSaveErrorDetails = false
    saveErrors: any = []
    isSaving = false
    deleteDialog = false
    deleteDialogValid = false
    deleteReason = null
    isCreditModes = [
      { id: null, name: '' },
      { id: false, name: 'Invoice' },
      { id: true, name: 'Credit' },
    ]

    requiredColor = '#fee'
    recommendedColor = '#ffe'
    eontyreOrders = []
    eontyreOrdersLoading = false
    eontyreOrdersError = ''
    showNewTag = false
    newTagName = ''
    newTagColor = ''
    productTypes = []
    approvalStatuses = []
    autoAttestMaxDifference = 0

    invoiceNumberRules = [(v) => !v || /^[A-Za-z0-9\-/]+$/.test(v) || 'Invalid (ex: 123 or A-123 or A/123)']

    supplierOrderNumberRules = [(v) => !v || /^[A-Za-z0-9\-/]+$/.test(v) || 'Invalid (ex: 123 or A-123 or A/123)']

    articleNumberRules = [(v) => !v || /^[A-Za-z0-9\-]+$/.test(v) || 'Invalid (ex: 123 or A-123)']

    orderIdsRules = [(v) => !v || /^\d+(,\d+)*$/.test(v) || 'Invalid order number (100 or 100,102,105)']

    dateRules = [(v) => !v || /^\d\d\d\d-\d\d-\d\d$/.test(v) || 'Invalid date (ex: YYYY-MM-DD)']

    ocrNumberRules = [(v) => !v || /^\d+$/.test(v) || 'Invalid number (ex: 123)']

    decimalRules = [(v) => !v || /^-?\d+(\.\d+)?$/.test(v) || 'Invalid decimal (ex: 10 or -10.50)']

    positiveDecimalRules = [(v) => !v || /^\d+(\.\d+)?$/.test(v) || 'Invalid decimal (ex: 10 or 10.50)']

    currencyRules = [(v) => !v || /^[A-Za-z]{3}$/.test(v) || 'Invalid currency (ex: EUR)']

    deleteReasonRules = [(v) => v.length <= 255 || 'Max 255 characters']

    tags = []

    /*
			  { 'name': 'Credited', 'color': '#0f0' },
			  { 'name': 'Request credit', 'color': '#f00' }
		  ]
		 */

    get isEditable() {
      return this.invoice && !this.isSentToErp(this.invoice.erpStatus)
    }

    get sumLines() {
      let sum = 0
      if (!this.invoice || !this.invoice.lines) {
        return sum
      }
      for (let i = 0; i < this.invoice.lines.length; i++) {
        const line = this.invoice.lines[i]
        sum += parseFloat(line.priceAllUnitsAfterDiscount) || 0
      }
      return Math.round(sum * 100) / 100
    }

    get sumLinesOk() {
      const diff = this.sumLines - this.invoice.netAmount
      return Math.abs(diff) < 0.0001
    }

    get headers() {
      return [
        { text: this.$t('Product type'), value: 'productType', width: 160 },
        { text: this.$t('Article number'), value: 'articleNumber', width: 200 },
        { text: this.$t('Description'), value: 'description' },
        { text: this.$t('Quantity'), value: 'quantity', width: 80 },
        { text: this.$t('UnitPrice'), value: 'pricePerUnitBeforeDiscount', width: 100 },
        { text: this.$t('SumPrice'), value: 'priceAllUnitsAfterDiscount', width: 120 },
        { text: this.$t('Discount'), value: 'discount', width: 90 },
        { text: this.$t('Actions'), value: 'actions', width: 120 },
      ].filter((i) => !!i)
    }

    get eontyreOrderIdsText() {
      return this.invoice && this.invoice.eontyreOrderIds ? this.invoice.eontyreOrderIds.join(',') : ''
    }

    get eontyreOrderLink() {
      if (this.invoice && this.invoice.eontyreOrderIds) {
        return appendSitePrefix('/' + this.invoice.eontyreOrderIds[0] + '/order2/main')
      }
      return ''
    }

    set eontyreOrderIdsText(value) {
      if (value) {
        this.invoice.eontyreOrderIds = value.split(',')
      } else {
        this.invoice.eontyreOrderIds = []
      }
    }

    get invoiceNetForDiff() {
      let amount = this.invoice && this.invoice.netAmount ? parseFloat(this.invoice.netAmount) : 0
      if (this.invoice.isCredit) {
        amount = 0 - amount
      }
      return Math.round(amount * 100) / 100
    }

    get sumEontyreOrders() {
      let sum = 0
      for (let i = 0; i < this.eontyreOrders.length; i++) {
        sum += this.eontyreOrders[i].quantity * this.eontyreOrders[i].unitCost
      }
      return Math.round(sum * 100) / 100
    }

    get totalDiff() {
      return Math.round((this.invoiceNetForDiff - this.sumEontyreOrders) * 100) / 100
    }

    get isTotalDiffOk() {
      const diff = Math.abs(this.totalDiff)
      return diff < this.autoAttestMaxDifference
    }

    mounted() {
      this.loading = true
      const url = '/v3/suppliers/invoices/' + this.$route.params.id
      this.getTags()
      this.$axios.get('/v3/suppliers/invoices/get_initial_data').then((response) => {
        this.approvalStatuses = response.data.data.approvalStatuses
        this.productTypes = response.data.data.productTypes
        this.suppliers = response.data.data.suppliers
        this.autoAttestMaxDifference = response.data.data.autoAttestMaxDifference
        this.$axios.get(url).then((response) => {
          this.setInvoice(response.data.data)
          this.loading = false
          this.downloadFile(this.invoice)
        })
      })
    }

    @Watch('invoice.eontyreOrderIds')
    onChangeOrderIds() {
      this.lookupEontyreOrders()
    }

    @Watch('invoice.supplierId')
    onChangeSupplierId() {
      this.lookupEontyreOrders()
    }

    lookupEontyreOrders() {
      console.log('chg:', this.eontyreOrderIdsText)
      if (this.eontyreOrderIdsText && !this.eontyreOrderIdsText.match(/^\d+(\,\d+)*$/)) {
        return // is typing/invalid
      }
      if (!this.eontyreOrderIdsText) {
        this.eontyreOrders = []
        return
      }
      this.eontyreOrdersError = ''
      this.eontyreOrdersLoading = true
      this.$axios
        .get('/v3/suppliers/invoice-related-orders/' + this.invoice.supplierId + '/' + this.eontyreOrderIdsText)
        .then((response) => {
          this.eontyreOrdersError = ''
          this.eontyreOrdersLoading = false
          this.eontyreOrders = response.data.data || []
        })
        .catch((err) => {
          console.error('Failed to fetch eontyre orders:', err)
          this.eontyreOrdersError = 'Error'
          this.eontyreOrdersLoading = false
          this.eontyreOrders = []
        })
    }

    getLineDiscountAmount(line) {
      const before = parseFloat(line.quantity) * parseFloat(line.pricePerUnitBeforeDiscount)
      const after = parseFloat(line.priceAllUnitsAfterDiscount)
      const discount = before - after
      return Math.round(discount * 100) / 100
    }

    getLineDiscountPercent(line) {
      const before = parseFloat(line.quantity) * parseFloat(line.pricePerUnitBeforeDiscount)
      const after = parseFloat(line.priceAllUnitsAfterDiscount)
      if (before === 0 && after === 0) {
        return 0
      }
      const discount = (1 - after / before) * 100
      return Math.round(discount * 10) / 10
    }

    setInvoice(data) {
      for (let i = 0; i < data.lines.length; i++) {
        data.lines[i].index = i
      }
      this.invoice = Object.assign({}, data)
      this.cleanInvoice = JSON.parse(JSON.stringify(this.invoice))
    }

    addLine() {
      let maxIndex = 0
      for (let i = 0; i < this.invoice.lines.length; i++) {
        const index = this.invoice.lines[i].index
        if (index > maxIndex) {
          maxIndex = index
        }
      }
      this.invoice.lines.push({
        index: maxIndex + 1,
        productType: null,
        articleNumber: '',
        description: '',
        quantity: null,
        pricePerUnitBeforeDiscount: null,
        priceAllUnitsAfterDiscount: null,
      })
    }

    removeLine(line) {
      let found = null
      for (let i = 0; i < this.invoice.lines.length; i++) {
        if (this.invoice.lines[i].index === line.index) {
          found = i
        }
      }
      if (found === null) {
        return
      }
      this.invoice.lines.splice(found, 1)
    }

    formatDate(value) {
      if (!value) {
        return ''
      }
      return value.split(' ')[0]
    }

    isSentToErp(status) {
      return status === 2 // todo: const
    }

    downloadFile(invoice) {
      const url = '/v3/suppliers/invoices/' + invoice.id + '/file'
      this.$axios.get(url).then((response) => {
        this.pdfData = 'data:application/pdf;base64,' + response.data.data
        this.showPdf = true
      })
    }

    reset() {
      this.invoice = JSON.parse(JSON.stringify(this.cleanInvoice))
    }

    save() {
      this.isSaving = true
      this.saveErrorMessage = ''
      this.saveErrors = []

      this.$axios
        .post('/v3/suppliers/invoices/' + this.invoice.id, this.invoice)
        .then((response) => {
          this.setInvoice(response.data.data)
          this.isSaving = false
        })
        .catch((err) => {
          this.isSaving = false
          if (err?.response && err.response.status === 400 && err.response.data.errors) {
            for (let i = 0; i < err.response.data.errors.length; i++) {
              let message = err.response.data.errors[i].message
              if (err.response.data.errors[i].details) {
                message += ' (' + err.response.data.errors[i].details.field + ')'
              }
              this.saveErrors.push(message)
            }
            this.saveErrorMessage += 'Please correct error fields and try again'
          } else if (err?.response?.data.errors?.[0]?.message) {
            this.saveErrorMessage += err.response.data.errors[0].message || 'Unknown error'
          } else {
            this.saveErrorMessage = 'Unknown error'
          }
        })
    }

    deleteInvoice() {
      if (!this.deleteDialogValid) {
        return
      }

      const inputData = {
        id: this.invoice.id,
        deleteReason: this.deleteReason,
      }

      this.$axios
        .delete('/v3/suppliers/invoices/' + this.invoice.id, { data: inputData })
        .then((response) => {
          vxm.alert.info({
            content: this.$t('Invoice deleted successfully') as string,
            title: this.$t('Info') as string,
          })
          this.$router.push({ name: 'Suppliers/Invoices' })
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
    }

    removeTag(item) {
      const index = this.invoice.tags.indexOf(item.id)
      if (index >= 0) this.invoice.tags.splice(index, 1)
    }

    saveNewTag() {
      this.$axios
        .post('/v3/suppliers/invoice-tags', { name: this.newTagName, color: this.newTagColor })
        .then((response) => {
          this.tags.push(response.data.data)
          this.newTagName = ''
          this.showNewTag = false
        })
        .catch((err) => {
          alert('Error saving new tag')
        })
    }

    getTags() {
      this.$axios
        .get('/v3/suppliers/invoice-tags')
        .then((response) => {
          this.tags = response.data.data
        })
        .catch((err) => {
          console.error('Failed to fetch tags: ', err)
        })
    }
  }
